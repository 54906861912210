(function () {
    'use strict';

    angular.module('telefone')
        .directive('inputTelefone', InputTelefone);

    InputTelefone.$inject = [];

    function InputTelefone() {

        var directiveConfig = {};

        directiveConfig.templateUrl = 'input/telefone.directive.html';
        directiveConfig.restrict = 'E';
        directiveConfig.require = 'ngModel';
        directiveConfig.scope = {};
        directiveConfig.scope.ngModel = '=';
        directiveConfig.scope.required = '=';

        directiveConfig.controller = InputTelefoneCtrl;
        directiveConfig.controllerAs = 'vm';

        return directiveConfig;
    }

    InputTelefoneCtrl.$inject = ['$scope', 'ModalTelefoneService', '$element', '$filter'];

    function InputTelefoneCtrl($scope, ModalTelefoneService, $element, $filter) {
        var vm = this;

        vm.click = click;
        vm.formatterTag = $filter('telefoneCompleto');

        if (!$scope.ngModel) {
            $scope.ngModel = [];
        }

        function click() {
            ModalTelefoneService.open($scope.ngModel).onClose(onClose);
        }

        function onClose() {
            $element.find('input').focus();
        }
    }
})();
