(function () {
    'use strict';

    angular.module('deficiencia')
        .service('ModalDeficienciaService', ModalDeficienciaService);

    ModalDeficienciaService.$inject = ['$q', 'bfc.dialog.Dialog'];
    function ModalDeficienciaService($q, bfcDialog) {
        return {open: open};

        function open(deficiencias) {
            var eventEmitter = new EventEmitter();

            bfcDialog.open({
                templateUrl: 'modal/deficiencia-modal.html',
                controller: 'DeficienciaCadCtrl',
                controllerAs: 'vm',

                resolve: {
                    eventEmitter: eventEmitter,
                    deficiencias: $q.when(deficiencias)
                }
            });
            return eventEmitter;
        }
    }

    function EventEmitter() {
        var self = this;
        self.handlers = {};

        self.on = on;
        self.emit = emit;

        //shorthands
        self.onSave = onSave;
        self.onRemove = onRemove;
        self.onClose = onClose;

        function on(eventName, handler) {
            if (!self.handlers[eventName]) {
                self.handlers[eventName] = [];
            }
            self.handlers[eventName].push(handler);
            return self;
        }

        function emit() {
            var eventName = arguments[0];
            var params = _.rest(arguments);

            var eventHandlers = self.handlers[eventName] || [];

            eventHandlers.forEach(function (handler) {
                handler.apply(this, params);
            });
            return self;
        }

        //shorthands
        function onSave(handler) {
            return self.on('save', handler);
        }

        function onRemove(handler) {
            return self.on('remove', handler);
        }

        function onClose(handler) {
            return self.on('close', handler);
        }
    }
})();