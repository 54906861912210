(function() {
    'use strict';

    angular.module('e-mail')
        .directive('inputEmail', InputEmail);

    InputEmail.$inject = [];

    function InputEmail() {

        var directiveConfig = {};

        directiveConfig.templateUrl = 'input/email.directive.html';
        directiveConfig.restrict = 'E';
        directiveConfig.require = 'ngModel';
        directiveConfig.scope = {};
        directiveConfig.scope.ngModel = '=';
        directiveConfig.scope.required = '=';

        directiveConfig.controller = InputEmailCtrl;
        directiveConfig.controllerAs = 'vm';

        return directiveConfig;
    }

    InputEmailCtrl.$inject = ['$scope', 'ModalEmailService', '$element'];

    function InputEmailCtrl($scope, ModalEmailService, $element) {
        var vm = this;

        vm.click = click;
        vm.formatterTag = formatterTag;

        if (!$scope.ngModel) {
            $scope.ngModel = [];
        }

        function click() {
            ModalEmailService.open($scope.ngModel).onClose(onClose);
        }

        function formatterTag(email) {
            if (propertyValid(email.descricao) || email.descricao.length === 0) {
                return email.email;
            }
            return email.email + ' (' + email.descricao + ')';
        }

        function propertyValid(value) {
            return value === null || angular.isUndefined(value);
        }

        function onClose() {
            $element.find('input').focus();
        }
    }
})();
