(function () {
    'use strict';

    angular.module('ui-components')
        .provider('GRecaptchaConfig', provider);

    provider.$inject = [];
    function provider() {
        var dados = {
            scriptUrl: undefined,
            siteKey: undefined
        };

        var setters = {$get: build};
        var getters = {};

        _.forEach(dados, function (value, key) {
            var normalized = _.capitalize(key);

            setters['set' + normalized] = function (value) {
                dados[key] = value;
            };
            getters['get' + normalized] = function () {
                return dados[key];
            };
        });
        return setters;

        function build() {
            return getters;
        }
    }
})();