(function () {
    'use strict';

    angular.module('telefone')
        .filter('telefoneCompleto', filter);

    filter.$inject = ['$filter'];
    function filter($filter) {
        var bfMaskPhone = $filter('bfMaskPhone');

        return function (telefone) {
            return bfMaskPhone(telefone.telefone) + (_.isEmpty(telefone.descricao) ? '' : ' (' + telefone.descricao + ')');
        };
    }

})();
