(function() {
	'use strict';

	angular.module( 'deficiencia')
		.directive('inputDeficiencia', InputDeficiencia);

	InputDeficiencia.$inject = [];

	function InputDeficiencia() {
		
		var directiveConfig = {};
		
        directiveConfig.templateUrl = 'input/deficiencia.directive.html';
		directiveConfig.restrict = 'E';
		directiveConfig.require = 'ngModel';
        directiveConfig.scope = {};
        directiveConfig.scope.ngModel = '=';
        directiveConfig.scope.required="=";
        
		directiveConfig.controller = InputDeficienciaCtrl;
		directiveConfig.controllerAs = 'vm';
		
		return directiveConfig;
	}
    
    InputDeficienciaCtrl.$inject = ['$injector', '$scope', 'ModalDeficienciaService', '$element'];
    
    function InputDeficienciaCtrl($injector, $scope, ModalDeficienciaService, $element){
        var vm = this, tipoDeficienciaOptions;
        var deficienciaService = $injector.get('deficienciaService');


        vm.click = click;
        vm.formatterTag = formatterTag;
        vm.show = false;
        
        if(!$scope.ngModel){
            $scope.ngModel = [];
        }

        deficienciaService.getTipoDeficiencia().then(function(data){
            tipoDeficienciaOptions = data;
            vm.show = true;
        });

        function click() {
            ModalDeficienciaService.open($scope.ngModel)
                .onClose(onClose);
        }

        function formatterTag(deficiencia){
            return tipoDeficienciaOptions[deficiencia.tipoDeficiencia].description;
        }
        
        function onClose(){
           $element.find('input').focus(); 
        }

    }
})();