(function () {
    'use strict';

    angular.module( 'e-mail')
        .service('ModalEmailService', ModalEmailService);

    ModalEmailService.$inject = ['$q', 'bfc.dialog.Dialog'];
    function ModalEmailService($q, bfcDialog) {
       return {open: open};

        function open(emails) {
            var eventEmitter = new EventEmitter();

            bfcDialog.open({
                templateUrl: 'modal/email-modal.html',
                controller: 'EmailCadCtrl',
                controllerAs: 'vm',

                resolve: {
                    eventEmitter: eventEmitter,
                    emails: $q.when(emails)
                }
            });
            return eventEmitter;
        }
    }

    function EventEmitter() {
        var self = this;
        self.handlers = {};

        self.on = on;
        self.emit = emit;
        self.getEvent = getEvent;

        //shorthands
        self.onSave = onSave;
        self.onRemove = onRemove;
        self.onClose = onClose;

        function on(eventName, handler) {
            if (!self.handlers[eventName]) {
                self.handlers[eventName] = [];
            }
            self.handlers[eventName].push(handler);
            return self;
        }

        function emit() {
            var eventName = arguments[0];
            var params = _.rest(arguments);

            var eventHandlers = self.handlers[eventName] || [];

            eventHandlers.forEach(function (handler) {
                handler.apply(this, params);
            });
            return self;
        }

        //shorthands
        function onSave(handler) {
            return self.on('save', handler);
        }

        function onRemove(handler) {
            return self.on('remove', handler);
        }

        function onClose(handler) {
            return self.on('close', handler);
        }

        function getEvent(eventName, nameFunction){
            return _.indexBy(self.handlers[eventName], 'name')[nameFunction];
        }

    }
})();