(function() {
    'use strict';

    angular.module('e-mail')
        .controller('EmailCadCtrl', EmailCadCtrl);

    EmailCadCtrl.$inject = ['bfc.Focus', '$modalInstance', '$modalTracker', 'emails', 'eventEmitter', '$injector'];

    function EmailCadCtrl(focus, $modalInstance, $modalTracker, emails, eventEmitter, $injector) {
        var vm = this,
            tipoEmail;

        var emailService = $injector.get('emailService');

        vm.emails = emails;
        vm.email = {};
        vm.editando = false;
        vm.formatDescricao = formatDescricao;

        $modalInstance.onClose(function() {
            eventEmitter.emit('close');
        });
        $modalInstance.onClose(setTipoEmail);

        var propriedadesPublicas = {
            close: close,
            save: save,
            remove: remove,
            edit: edit
        };

        vm.sortableOptions = {
            placeholder: 'ui-card-sortable',
            cursor: 'move',
            axis: 'y',
            containment: '#ui-containment',
            forcePlaceholderSize: true,
            tolerance: 'pointer'
        };

        init();

        function init() {
            angular.extend(vm, propriedadesPublicas);
        }

        function save() {
            if (vm.editando) {
                vm.emails.splice(vm.indexToEdit, 1);
            }
            vm.emails.push(angular.copy(vm.email));
            eventEmitter.emit('save', vm.emails);

            if (!!vm.editando) {
                vm.editando = false;
            }

            clear();

        }

        function formatDescricao(email) {

            if (propertyValid(email.descricao) || email.descricao.length === 0) {
                return email.email;
            }
            return email.email + ' (' + email.descricao.toUpperCase() + ')';
        }

        function propertyValid(value) {
            return value === null || angular.isUndefined(value);
        }

        function clear() {
            vm.email.descricao = null;
            vm.email.email = null;
            vm.indexToEdit = null;
            vm.form.$setPristine();
            vm.form.$setUntouched();
            focus('descricao');
        }

        function remove(index) {
            vm.emails.splice(index, 1);
            eventEmitter.emit('remove', vm.emails);
        }

        function close() {
            $modalInstance.close();
        }


        function setTipoEmail() {
            angular.forEach(vm.emails, function(email, index) {
                email.tipo = index === 0 ? 'PRINCIPAL' : 'OUTRO';
            });
        }

        function edit(index) {

            vm.email.descricao = angular.copy(vm.emails[index].descricao);
            vm.email.email = angular.copy(vm.emails[index].email);


            vm.indexToEdit = index;

            vm.editando = true;
        }
    }
})();
