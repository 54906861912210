(function() {
    'use strict';

    var tipoTelefone = [{
        key: "FIXO",
        value: 0,
        description: "Fixo"
    }, {
        key: "CELULAR",
        value: 1,
        description: "Celular"
    }, {
        key: "FAX",
        value: 2,
        description: "Fax"
    }];

    angular.module('telefone')
        .controller('telefone.TelefoneCadCtrl', TelefoneCadCtrl);

    TelefoneCadCtrl.$inject = ['$q', 'bfc.Focus', '$modalInstance', 'telefones', 'eventEmitter', 'telefoneService', '$modalTracker',
        '$filter'];

    function TelefoneCadCtrl($q, focus, $modalInstance, telefones, eventEmitter, telefoneService, $modalTracker, $filter) {
        var vm = this;

        $modalInstance.onClose(function() {
            eventEmitter.emit('close');
        });

        $modalInstance.onClose(setTipoTelefone);

        vm.options = {};
        vm.tracker = {};
        vm.telefone = {};

        vm.telefones = telefones;
        vm.editando = false;

        var propriedadesPublicas = {
            close: close,
            save: save,
            remove: remove,
            edit: edit
        };

        vm.sortableOptions = {
            placeholder: 'ui-card-sortable',
            cursor: 'move',
            axis: 'y',
            containment: '#ui-containment',
            forcePlaceholderSize: true,
            tolerance: 'pointer'
        };

        init();

        function init() {
            vm.tipoNumeroOptions = createPromise(tipoTelefone);
            angular.extend(vm, propriedadesPublicas);
        }

        function createPromise(values) {
            var createdEnum = {
                values: values
            };

            _.forEach(values, function(value) {
                value.id = value.key;
                createdEnum[value.key] = value;
            })

            return $q.when(createdEnum);
        }

        function save() {
            if (vm.editando) {
                vm.telefones.splice(vm.indexToEdit, 1);
                vm.editando = false;
            }
            vm.telefones.push(angular.copy(vm.telefone));

            eventEmitter.emit('save', vm.telefones);

            clear();
        }

        function clear() {
            angular.forEach(vm.telefone, function(atributte, index) {
                vm.telefone[index] = null;
            });
            vm.indexToEdit = null;
            vm.form.$setPristine();
            vm.form.$setUntouched();

            focus('descricao');

        }

        function remove(index) {
            vm.telefones.splice(index, 1);
            eventEmitter.emit('remove', vm.telefones);
        }

        function close() {
            $modalInstance.close();
        }

        function edit(index) {
            vm.telefone = angular.copy(vm.telefones[index]);

            vm.indexToEdit = index;
            vm.editando = true;
        }

        function setTipoTelefone() {
            angular.forEach(vm.telefones, function(telefone, index) {

                telefone.tipo = index === 0 ? 'PRINCIPAL' : 'OUTRO';
            });
        }
    }
})();
