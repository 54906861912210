(function () {
    /**
     * Created by marlon.souza on 14/09/2015.
     */

    'use strict';

    angular.module('e-mail')
        .provider('emailService', emailService);

    function emailService() {
        var self = this;

        self.uri = undefined;
        self.path = path;
        self.$get = ['ServiceBuilder', getEmailService];

        function path(uri){
            self.uri = uri;
        };

        function getEmailService(ServiceBuilder){
            if(angular.isUndefined(self.uri)){
                throw new Error('configuration to emailServiceProvider is undefined!');
            }

            return {};//ServiceBuilder.create().path(self.uri).enum('TipoEmail').build();
        }
    }
})();