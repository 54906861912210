(function() {
    'use strict';

    angular.module('deficiencia')
        .controller('DeficienciaCadCtrl', DeficienciaCadCtrl);

    DeficienciaCadCtrl.$inject = ['bfc.Focus', '$scope', '$modalInstance', 'deficiencias', 'eventEmitter', 'deficienciaService'];

    function DeficienciaCadCtrl(focus, $scope, $modalInstance, deficiencias, eventEmitter, deficienciaService) {
        var vm = this;

        vm.deficiencia = {};
        vm.deficiencias = deficiencias;

        vm.editando = false;

        vm.close = close;
        vm.save = save;
        vm.remove = remove;
        vm.edit = edit;

        init();

        function init() {
            vm.grauDeficienciaOptions = deficienciaService.getGrauDeficiencia();
            vm.grauDeficienciaOptions.then(function(data) {
                vm.grauDeficienciaEnum = data;
            });

            vm.tipoDeficienciaOptions = deficienciaService.getTipoDeficiencia();
            vm.tipoDeficienciaOptions.then(function(data) {
                vm.tipoDeficienciaEnum = data;
            });

            $modalInstance.onClose(function() {
                eventEmitter.emit('close');
            });
        }

        function save() {
            if (vm.editando) {
                vm.deficiencias.splice(vm.indexToEdit, 1);
                vm.editando = false;
            }
            vm.deficiencias.push(angular.copy(vm.deficiencia));
            eventEmitter.emit('save', vm.deficiencias);


            clear();
        }

        function clear() {
            vm.deficiencia.tipoDeficiencia = null;
            vm.deficiencia.grau = null;
            vm.indexEdicao = null;

            $scope.form.$setPristine();
            $scope.form.$setUntouched();

            focus('descricao');
        }

        function remove(index) {
            vm.deficiencias.splice(index, 1);
        }

        function close() {
            $modalInstance.close();
        }

        function edit(index) {
            vm.deficiencia.tipoDeficiencia = angular.copy(vm.deficiencias[index].tipoDeficiencia);
            vm.deficiencia.grau = angular.copy(vm.deficiencias[index].grau);

            vm.indexToEdit = index;
            vm.editando = true;
        }

    }
})();
