/**
 * Created by marlon.souza on 01/09/2015.
 */

(function () {
    'use strict';

    angular.module('deficiencia')
        .provider('deficienciaService', deficienciaService);

    function deficienciaService() {
        var self = this;

        self.uri = undefined;
        self.path = path;
        self.$get = ['ServiceBuilder', getDeficienciaService];

        function path(uri){
            self.uri = uri;
        };

        function getDeficienciaService(ServiceBuilder){
            if(angular.isUndefined(self.uri)){
                throw new Error('configuration to deficienciaServiceProvider is undefined!');
            }

            return ServiceBuilder.create()
                .path(self.uri)
                .enum('GrauDeficiencia', 'TipoDeficiencia')
                .build();
        }
    }
})();
