(function () {
    'use strict';

    angular.module('ui-components')
        .service('GRecaptchaLoader', service);

    service.$inject = ['GRecaptchaConfig'];
    function service(GRecaptchaConfig) {
        var deferred = $.Deferred();

        if (window.grecaptcha) {
            deferred.resolve(window.grecaptcha);
        } else {
            loadScript();
        }
        return deferred.promise();

        function loadScript() {
            window.GRecaptchaLoaderOnloadCallback = onScriptLoaded;

            $.getScript(GRecaptchaConfig.getScriptUrl() + '?onload=GRecaptchaLoaderOnloadCallback&render=explicit&hl=pt-BR', function (data, textStatus, jqxhr) {
                if (jqxhr.status !== 200) {
                    deferred.reject();
                }
            });
        }

        function onScriptLoaded() {
            delete window.GRecaptchaLoaderOnloadCallback;
            deferred.resolve(window.grecaptcha);
        }
    }
})();