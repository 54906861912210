(function () {
    'use strict';

    angular.module('ui-components')
        .directive('gRecaptcha', directive);

    function directive() {
        return {
            restrinct: 'E',

            require: 'ngModel',
            scope: {
                model: '=ngModel'
            },

            link: addValidator,

            template: '<i bf-loading="vm.loading"></i>',

            controller: Controller,
            controllerAs: 'vm'
        };

        function addValidator(scope, el, attrs, ngModel) {
            ngModel.$validators.required = function (value) {
                return !_.isEmpty(value);
            };
        }
    }

    Controller.$inject = ['$scope', '$element', 'promiseTracker', 'GRecaptchaLoader', 'GRecaptchaConfig'];
    function Controller($scope, $element, promiseTracker, GRecaptchaLoader, GRecaptchaConfig) {
        var vm = this;

        vm.loading = promiseTracker();
        vm.loading.addPromise(GRecaptchaLoader.then(installRecaptcha));

        function installRecaptcha(grecaptcha) {
            var captchaElement = $('<div>');

            grecaptcha.render(captchaElement[0], {
                'sitekey': GRecaptchaConfig.getSiteKey(),
                'callback': setModelValue,
                'expired-callback': cleanModelValue
            });

            $element.empty().append(captchaElement);
            vm.loading = null;
        }

        function setModelValue(value) {
            $scope.model = value;
            $scope.$apply();
        }

        function cleanModelValue() {
            $scope.model = null;
            $scope.$apply();
        }
    }
})();